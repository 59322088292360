<template>
  <v-layout justify-center align-center>
    <v-flex md8 lg6>
      <form @submit.prevent="submit()">
        <v-card>
          <v-card-title>
            <div class="title">
              {{ $lang("AUTH:FORGOT_PASSWORD:CARD_TITLE-TEXT") }}
            </div>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-flex v-if="showResetCodeBox && user.email.length > 0">
              <v-chip small>{{ user.email }}</v-chip>
              <v-btn small text @click="init()">{{ $lang("CHANGE") }}</v-btn>
            </v-flex>
            <v-text-field
              v-if="!showResetCodeBox"
              prepend-icon="mdi-email"
              :label="$lang('AUTH:FORGOT_PASSWORD:INPUT_TEXT_EMAIL-LABEL')"
              :hint="$lang('AUTH:FORGOT_PASSWORD:INPUT_TEXT_EMAIL-HINT')"
              type="email"
              v-model="user.email"
              :error-messages="errors && errors.email ? errors.email : []"
            ></v-text-field>

            <v-text-field
              v-if="showResetCodeBox"
              prepend-icon="mdi-code"
              :label="$lang('AUTH:FORGOT_PASSWORD:INPUT_TEXT_CODE-LABEL')"
              :hint="$lang('AUTH:FORGOT_PASSWORD:INPUT_TEXT_CODE-HINT')"
              v-model="user.token"
              :error-messages="errors && errors.token ? errors.token : []"
            ></v-text-field>

            <v-text-field
              v-if="showResetCodeBox"
              prepend-icon="mdi-lock"
              :label="$lang('AUTH:FORGOT_PASSWORD:INPUT_TEXT_PASSWORD-LABEL')"
              :hint="$lang('AUTH:FORGOT_PASSWORD:INPUT_TEXT_PASSWORD-HINT')"
              v-model="user.password"
              type="password"
              :error-messages="errors && errors.password ? errors.password : []"
            ></v-text-field>

            <v-text-field
              v-if="showResetCodeBox"
              prepend-icon="mdi-lock"
              :label="
                $lang('AUTH:FORGOT_PASSWORD:INPUT_TEXT_PASSWORD_CONFIRM-LABEL')
              "
              :hint="
                $lang('AUTH:FORGOT_PASSWORD:INPUT_TEXT_PASSWORD_CONFIRM-HINT')
              "
              v-model="user.password_confirm"
              type="password"
              :error-messages="
                errors && errors.password_confirm ? errors.password_confirm : []
              "
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="primary"
              outlined
              v-if="!showResetCodeBox"
              type="submit"
            >
              {{ $lang("Send Reset Code") }}
            </v-btn>
            <v-btn
              color="primary"
              outlined
              v-if="showResetCodeBox"
              @click="recoverPassword()"
            >
              {{ $lang("Change Password") }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              text
              v-if="!showResetCodeBox"
              @click.stop="
                () => {
                  showResetCodeBox = true;
                }
              "
            >
              {{ $lang("GotOTP") }}
            </v-btn>
            <v-btn outlined v-if="showResetCodeBox" @click="submit()">
              {{ $lang("AUTH:FORGOT_PASSWORD:BUTTON_SEND_AGAIN-TEXT") }}
            </v-btn>
          </v-card-actions>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn text small @click="$router.push({ name: 'login' })">{{
              $lang("AUTH:FORGOT_PASSWORD:BUTTON_LOGIN-TEXT")
            }}</v-btn>
            <v-spacer></v-spacer>
            <v-btn text small @click="$router.push({ name: 'register' })">{{
              $lang("AUTH:FORGOT_PASSWORD:BUTTON_REGISTER-TEXT")
            }}</v-btn>
          </v-card-actions>
        </v-card>
      </form>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "forgot-password",
  data() {
    return {
      user: {
        email: "",
        password: "",
        password_confirm: "",
        token: "",
      },
      wait: false,
      showResetCodeBox: false,
      validate: false,
    };
  },
  computed: {
    ...mapGetters({
      auth: "auth/auth",
    }),
    errors: {
      get() {
        return this.$store.getters.getFormErrors;
      },
      set(_) {},
    },
  },
  created() {
    if (this.$route.query.code && this.$route.query.email) {
      this.user.email = this.$route.query.email.trim();
      this.user.token = this.$route.query.code.trim();
      this.showResetCodeBox = true;
    }
  },

  methods: {
    ...mapActions({
      forgotPassword: "auth/forgotPassword",
      RecoverPassword: "auth/recoverPassword",
    }),
    init() {
      this.user = {
        email: "",
        password: "",
        password_confirm: "",
        token: "",
      };
      this.errors = null;
      this.wait = false;
      this.showResetCodeBox = false;
      this.validate = false;
    },
    recoverPassword() {
      var user = {
        email: this.user.email.trim(),
        token: this.user.token.trim(),
        password: this.user.password.trim(),
        password_confirm: this.user.password_confirm.trim(),
      };
      console.log("user", user);
      this.RecoverPassword({
        user: user,
        context: this,
      })
        .then((res) => {
          // success
          // this.init();
          this.$router.replace({
            name: "login",
          });
        })
        .catch((err) => {});
    },
    submit() {
      if (!this.user || !this.user.email) {
        return;
      }
      this.wait = true;
      this.showResetCodeBox = false;
      var user = {
        email: this.user.email.trim(),
      };
      this.forgotPassword({
        user: user,
        context: this,
      })
        .then(() => {
          // this.init();
          this.showResetCodeBox = true;
          this.user.email = user.email;
        })
        .catch((e) => {
          // if (!this.errors.email) this.init();
        });
    },
  },
};
</script>
